import Matrix from "./components/Matrix";
import Menu from "./components/Menu";

const App = () => {
  
  let menu = [
    {
      name: "Jogos",
      url: "https://trapcat.com"
    },
    {
      name: "Artigos",
      url: "https://medium.com/@marciojmo"
    },
    {
      name: "Instagram",
      url: "https://instagram.com/marciojmo"
    },
    {
      name: "Twitter",
      url: "https://twitter.com/@marciojmo"
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/@marcio.oliveira"
    },
    {
      name: "E-book Começando a Programar Bem!",
      url: "https://docs.google.com/forms/d/e/1FAIpQLSfW4tC5mQlaaZG_Z4jXBO3efoj4sUNx9E4I6bHBZO3J3APV3Q/viewform?usp=sf_link"
    },
    {
      name: "Curso Online",
      url: "https://docs.google.com/forms/d/e/1FAIpQLSfW4tC5mQlaaZG_Z4jXBO3efoj4sUNx9E4I6bHBZO3J3APV3Q/viewform?usp=sf_link"
    },

  ];

  return (
    <div>
      <Matrix/>
      <div className="absolute w-full h-full z-10 flex">
        <Menu options={menu}/>        
      </div>
    </div>
  );
}

export default App;
